import React from 'react'
import ReactDOM from 'react-dom'

import EnquiryForm from 'components/product/grid2/enquiry-form'

document.addEventListener('DOMContentLoaded', () => {

	const form = document.querySelector('#enquiry-form');
	const config = JSON.parse( form.getAttribute('config') );
	const type = form.getAttribute('type');

	ReactDOM.render(
		<EnquiryForm 
            config={config}
            type={type}
            hideLocation={false}
        />,
		form,
	)
});